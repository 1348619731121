<template>
	<div>
		<schedule
			:disable-lesson-click="true"
			:show-filter-bar="true"
			:lessen="scheduleData"
			:vestigingen="vestigingenAlphabetically"
			:groepslessen="groepslessenAlphabetically"
			:trainers="trainersAlphabetically"
			:initial-vestiging="initialVestigingId"
			:gesloten-dagen="geslotenDagen"
			occupancy-format="relative"
			@weekChange="changeWeek"
		></schedule>
	</div>
</template>

<script>
import moment from "moment";
import Schedule from "pfg-rooster/src/components/Schedule.vue";
import rooster from "@/lib/rooster";

export default {
	name: "ScheduleWrapper",
	components: {
		Schedule,
	},
	props: {
		initialVestigingId: {
			type: Number,
			default: -1,
		},
	},
	data() {
		return {
			trainers: [],
			groepslessen: [],
			vestigingen: [],
			geslotenDagen: [],
			lessen: [],
			vestigingId: -1,
			selectedLesson: {},
			showScheduleLessonPanel: false,
		};
	},
	computed: {
		scheduleData() {
			return this.lessen.map((les) => {
				this.$set(les, "trainer", this.getTrainerById(les.trainerId));
				this.$set(les, "zaal", this.getZaalById(les.zaalId));
				this.$set(les, "vestiging", this.getVestigingById(les.vestigingId));
				this.$set(les, "groepsles", this.getGroepslesById(les.groepslesId));
				// this.$set(les, 'herhalend', this.isHerhalendeReservering(les.roosterItemId));
				this.$set(les, "endDate", moment(les.datum).add(les.duur, "minute"));
				return les;
			});
		},
		groepslessenAlphabetically() {
			if (!this.groepslessen.length) {
				return [];
			}
			return [...this.groepslessen].sort((a, b) => {
				if (a.naam < b.naam) return -1;
				if (a.naam > b.naam) return 1;
				return 0;
			});
		},
		vestigingenAlphabetically() {
			if (!this.vestigingen.length) {
				return [];
			}
			return [...this.vestigingen].sort((a, b) => {
				if (a.naam < b.naam) return -1;
				if (a.naam > b.naam) return 1;
				return 0;
			});
		},
		trainersAlphabetically() {
			if (!this.trainers.length) {
				return [];
			}
			return [...this.trainers].sort((a, b) => {
				if (a.naam < b.naam) return -1;
				if (a.naam > b.naam) return 1;
				return 0;
			});
		},
		alternativeLessons() {
			const selectedLesson = this.selectedLesson;
			if (!selectedLesson) {
				return [];
			}
			return this.scheduleData
				.filter(
					(lesson) =>
						lesson.id !== selectedLesson.id &&
						lesson.vestigingId === selectedLesson.vestigingId &&
						lesson.groepslesId === selectedLesson.groepslesId &&
						lesson.status === "aankomend",
				)
				.sort((a, b) => {
					const diff = moment(a.datum).diff(moment(b.datum));
					return diff;
				});
		},
	},
	watch: {
		initialVestigingId: {
			immediate: true,
			handler(newVestigingId) {
				this.vestigingId = parseInt(newVestigingId, 10);
			},
		},
	},
	created() {
		this.populateData().catch((e) => {
			if (e) {
				this.error = true;
			}
		});
	},
	methods: {
		async populateData() {
			const week = moment().week();
			let weekYear;

			if (week === 1 && moment().startOf("week").year() !== moment().endOf("week").year()) {
				weekYear = moment().endOf("week").year();
			} else if (
				week === moment().startOf("week").weeksInYear() &&
				moment().startOf("week").year() !== moment().endOf("week").year()
			) {
				weekYear = moment().startOf("week").year();
			} else {
				weekYear = moment().year();
			}

			[this.trainers, this.vestigingen, this.groepslessen, this.lessen, this.geslotenDagen] =
				await Promise.all([
					rooster.getTrainers(),
					rooster.getVestigingen(),
					rooster.getGroepslessen(),
					rooster.getLessenOfWeek({
						week,
						year: weekYear,
					}),
					// rooster.getReserveringen(),
					rooster.getGeslotenDagen(),
				]);
		},
		getTrainerById(id) {
			return this.trainers.find((sTrainer) => sTrainer.id === id);
		},
		getZaalById(id) {
			return this.vestigingen
				.reduce((zalen, currentVestiging) => {
					return [...zalen, ...currentVestiging.zalen];
				}, [])
				.find((zaal) => zaal.id === id);
		},
		getVestigingById(id) {
			return this.vestigingen.find((sVestiging) => sVestiging.id === id);
		},
		getGroepslesById(id) {
			return this.groepslessen.find((groepsles) => groepsles.id === id);
		},
		async changeWeek(data) {
			this.loading = true;
			try {
				this.lessen = await rooster.getLessenOfWeek(data);
			} catch (e) {
				this.lessen = [];
			}
		},
		onScheduleLessonPanelClose() {
			this.showScheduleLessonPanel = false;
		},
	},
};
</script>
<style lang="scss" scoped>
.pfg-schedule {
	margin-top: 0;
}

::v-deep .pfg-schedule_location_switcher_select {
	margin-bottom: 0;
}
</style>
