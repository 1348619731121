import { render, staticRenderFns } from "./GroepslessenRooster.vue?vue&type=template&id=6e2c626c&scoped=true"
import script from "./GroepslessenRooster.vue?vue&type=script&lang=ts"
export * from "./GroepslessenRooster.vue?vue&type=script&lang=ts"
import style0 from "./GroepslessenRooster.vue?vue&type=style&index=0&id=6e2c626c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e2c626c",
  null
  
)

export default component.exports