













import { defineComponent } from "@vue/composition-api";
import { mapActions, mapState } from "pinia";
import ScheduleWrapper from "@/components/grouplessons/ScheduleWrapper.vue";
import { useLocationStore } from "@/pinia/location";

export default defineComponent({
	name: "PfgGroepslessenRooster",
	components: {
		ScheduleWrapper,
	},
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		...mapState(useLocationStore, {
			locationId: ({ location }) => location.id,
			locations: "locations",
		}),
	},
	async created() {
		this.loading = true;

		await this.initLocation();

		this.loading = false;
	},
	methods: {
		...mapActions(useLocationStore, {
			initLocation: "init",
		}),
	},
});
