import { config } from "@/lib/config";
import service from "@/lib/service";

const rooster = {
	getVestigingen() {
		return service.get(`${config.reserveer.server}/vestigingen`);
	},
	getGroepslessen() {
		return service.get(`${config.reserveer.server}/groepslessen`);
	},
	getTrainers() {
		return service.post(`${config.reserveer.server}/trainers`, {});
	},
	getLessenOfWeek({ week, year }) {
		return service
			.post(`${config.reserveer.server}/lessen/week/${week}/jaar/${year}`, {})
			.then((data) => {
				return data;
			});
	},
	getGeslotenDagen() {
		return service.get(`${config.reserveer.server}/geslotendagen`);
	},
};

export default rooster;
